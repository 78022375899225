<template>
  <b-container>
    <vue-simple-markdown
      id="yourElement"
      :source="$t(subject)"
    />
  </b-container>
</template>

<script>

export default {
  name: 'Page',
  components: {},
  mixins: [],
  props: {
    subject: {
      type: String,
      default: 'default'
    }
  },
  data () {
    return {
    }
  },
  computed: {},
  watch: {},
  mounted () {

  },
  methods: {}

}
</script>
